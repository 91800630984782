<template>
  <div class="industryVideo w">
    <div class="crumbs">
      <router-link to="/">首页</router-link>
      <span>/</span>
      便民服务
      <span>/</span>
      <em>行业视频</em>
    </div>
    <div class="videoList">
      <div class="item" v-for="item in list">
        <img :src="item.picturePath">
        <img class="player" src="../assets/player.png" @click="play(item.videoPath)">
      </div>

    </div>
    <div class="pagebox">
      <el-pagination
        layout="prev, pager, next"
        :page-size="12"
        :current-page="pageNo"
        @current-change="pageChange"
        :total="total">
      </el-pagination>
    </div>
    <div v-if="dialogVisible">
    <el-dialog
      v-model="dialogVisible"
      title="视频播放"
    >
      <video style="margin: 0 auto" width="800" height="500" controls autoplay>
        <source :src="videoPath" type="video/mp4" />
        你的浏览器不支持视频播放
      </video>
<!--      <template #footer>-->
<!--      <span class="dialog-footer">-->
<!--        <el-button @click="dialogVisible = false">Cancel</el-button>-->
<!--        <el-button type="primary" @click="dialogVisible = false">Confirm</el-button>-->
<!--      </span>-->
<!--      </template>-->
    </el-dialog>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed, watch} from 'vue'
import { getAction } from '../api'
import { ElPagination } from 'element3'

export default defineComponent({
  name: 'industryVideo',
  components: {
    ElPagination
  },
  setup() {
    const dialogVisible = ref(false)
    const list = ref([])
    const pageNo = ref(1)
    const total = ref(0)
    const videoPath = ref(null)

    const getVideoList = () => {
      getAction('/yncy/video/list', {pageNo: pageNo.value, pageSize: 12}).then(res => {
        list.value = res.result.records
        total.value = res.result.total
      })
    }
    const pageChange = (page) => {
      pageNo.value = page
      getListData()
    }

    const play = (path) => {
      dialogVisible.value = true
      videoPath.value = path
    }

    onMounted(() => {
      getVideoList()
    })

    return {list, total, pageNo, dialogVisible, videoPath, pageChange, play }
  }
})
</script>

<style lang="scss" scoped>
.industryVideo {
  .crumbs {
    em {
      font-style: normal;
      color: #07C160;
    }
  }

  .videoList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .item {
      position: relative;
      width: 23.3%;
      height: 200px;
      margin: 10px;

      img {
        width: 100%;
        height: 100%;
      }

      .player {
        position: absolute;
        cursor: pointer;
        left: 45%;
        top: 45%;
        width: 40px;
        height: 40px;
        z-index: 66;
      }
    }
  }
  /deep/ .el-pager li.active {
    background: #07C160;
    border-radius: 50%;
    color: #ffffff;
    padding: 0;
  }
  .pagebox {
    margin-top: 25px;
  }
   /deep/ .el-dialog{
     width: 850px !important;
    margin-top: 100px !important;
  }
}
</style>
