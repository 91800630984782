<template>
  <div style="background: #F5F5F5">
    <div class="w">
      <div class="crumbs">
        <router-link to="/">首页</router-link>
        <span>/</span>
        搜索
      </div>
      <div class="searchContent">
        <div class="searchContent-left">
          <div class="searchContent-left-title">按内容分类</div>
          <div class="searchContent-left-item" :class="{active: classification === 1}" @click="searchClassification(1)">
            <div class="searchContent-left-item-left">通知公告</div>
            <div class="searchContent-left-item-right"></div>
          </div>
          <div class="searchContent-left-item" :class="{active: classification === 2}" @click="searchClassification(2)">
            <div class="searchContent-left-item-left">要闻快讯</div>
            <div class="searchContent-left-item-right"></div>
          </div>
          <div class="searchContent-left-item" :class="{active: classification === 3}" @click="searchClassification(3)">
            <div class="searchContent-left-item-left">行业动态</div>
            <div class="searchContent-left-item-right"></div>
          </div>
          <div class="searchContent-left-item" :class="{active: classification === 4}" @click="searchClassification(4)">
            <div class="searchContent-left-item-left">监测预警</div>
            <div class="searchContent-left-item-right"></div>
          </div>
          <div class="searchContent-left-item" :class="{active: classification === 5}" @click="searchClassification(5)">
            <div class="searchContent-left-item-left">灾害防治</div>
            <div class="searchContent-left-item-right"></div>
          </div>
          <div class="searchContent-left-item" :class="{active: classification === 6}" @click="searchClassification(6)">
            <div class="searchContent-left-item-left">检疫执法</div>
            <div class="searchContent-left-item-right"></div>
          </div>
          <div class="searchContent-left-item" :class="{active: classification === 7}" @click="searchClassification(7)">
            <div class="searchContent-left-item-left">行业规范</div>
            <div class="searchContent-left-item-right"></div>
          </div>
          <div class="searchContent-left-item" :class="{active: classification === 8}" @click="searchClassification(8)">
            <div class="searchContent-left-item-left">国家政策</div>
            <div class="searchContent-left-item-right"></div>
          </div>
          <div class="searchContent-left-item" :class="{active: classification === 9}" @click="searchClassification(9)">
            <div class="searchContent-left-item-left">法律法规</div>
            <div class="searchContent-left-item-right"></div>
          </div>
        </div>
        <div class="searchContent-right">
          <div class="searchContent-right-keyWord">已为你搜索到<span style="color:#00BB3D ">{{ total }}</span>个 "<span
            style="color:#00BB3D ">{{ route.query.keyWord }}</span>" 结果
          </div>
            <div class="searchContent-right-result" v-for="item in searchList">
              <router-link :to="{path:'/grassDetial', query:{id:item.id,type:'搜索'}}">
              <div class="searchContent-rigjt-result-title">
                <div
                  style="height: 28px;font-size: 20px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 28px;">
                  【{{ getType(item.categoryNum) }}】{{ item.title.substring(0, 25) }}
                </div>
                <div
                  style="height: 18px;font-size: 14px;font-family: Montserrat-Regular, Montserrat;font-weight: 400;color: rgba(0, 0, 0, 0.85);line-height: 18px;">
                  {{ formartTime(item.createTime) }}
                </div>
              </div>
              <div class="searchContent-rigjt-result-content">
                {{ item.content ? delHtmlTag(item.content) : '' }}...
              </div>
              </router-link>
            </div>
          <div class="pagebox">
            <el-pagination
              layout="prev, pager, next"
              :page-size="12"
              :current-page="pageNo"
              @current-change="pageChange"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted} from 'vue'
import {getAction} from '../api'
import {useRoute} from 'vue-router'
import dayjs from 'dayjs'
import {ElPagination} from 'element3'

export default defineComponent({
  name: 'Industry',
  components: {
    ElPagination
  },
  watch: {
    '$route.query': function (val) {
      this.getSearchContent()
    }
  },
  setup() {
    const route = useRoute()
    const classification = ref(null)
    const pageNo = ref(1)
    const total = ref(0)
    const searchList = ref([])
    const getSearchContent = () => {
      getAction('/yncy/search/searchList', {
        content: route.query.keyWord,
        category: classification.value,
        pageNo: pageNo.value,
        pageSize: 10,
      }).then(res => {
        res.result.records.map((item, index) => {
        })
        searchList.value = res.result.records
        total.value = res.result.total
      })
    }
    const searchClassification = (type) => {
      classification.value = type
      getSearchContent()
    }
    const getType = (type) => {
      if (type === 1) return '通知公告'
      if (type === 2) return '要闻快讯'
      if (type === 3) return '行业动态'
      if (type === 4) return '监测预警'
      if (type === 5) return '灾害防治'
      if (type === 6) return '检疫执法'
      if (type === 7) return '行业规范'
      if (type === 8) return '国家政策'
      if (type === 9) return '法律法规'
    }
    const formartTime = (val) => {
      return dayjs(val).format('YYYY/MM/DD')
    }
    // 处理文本
    const delHtmlTag = (str) => {
      return str.replace(/<[^>]+>/g, '').replace(/(&.*?;){1,}/ig, '').substring(0, 120)
    }

    // 处理title关键子高亮
    const getTitle = (titleVal) => {
      if (route.query.keyWord) {
        const replaceString = '<span class="search-text">' + route.query.keyWord + '</span>'
        return '<div>'+titleVal.replace(route.query.keyWord, replaceString) + '</div>'
      }
    }
    const pageChange = (page) => {
      pageNo.value = page
      getSearchContent()
    }

    onMounted(() => {
      getSearchContent()
    })
    return {
      route,
      classification,
      searchList,
      pageNo,
      total,
      searchClassification,
      getSearchContent,
      getType,
      formartTime,
      delHtmlTag,
      getTitle,
      pageChange
    }
  }
})
</script>

<style lang="scss" scoped>
/deep/ .el-pager li.active {
  background: #07C160;
  border-radius: 50%;
  color: #ffffff;
  padding: 0;
}
.active{
  background: #07C160;
  color: #FFFFFF ;
}
.active {
  div{
    color: #FFFFFF;
  }
}
.pagebox{
  margin-top: 20px;
}
.searchContent {
  display: flex;
  margin-bottom: 20px;

  .searchContent-left {
    width: 250px;
    background: #FFFFFF;

    .searchContent-left-title {
      height: 80px;
      line-height: 80px;
      text-align: center;
      background: #00BB3D;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }

    .searchContent-left-item {
      height: 64px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .searchContent-left-item-right:after {
        content: "";
        width: 10px;
        height: 10px;
        display: inline-block;
        border-top: 2px solid darkgray;
        border-right: 2px solid darkgray;
        transform: rotate(45deg);
      }
    }
  }

  .searchContent-right {
    width: 930px;
    padding: 0 40px;
    margin-left: 20px;
    background: #FFFFFF;

    .searchContent-right-keyWord {
      margin-top: 48px;
      margin-bottom: 40px;
      height: 45px;
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 45px;
    }

    .searchContent-right-result {
      border-bottom: 1px solid #848484;
      height: 124px;

      .searchContent-rigjt-result-title {
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
      }

      .searchContent-rigjt-result-content {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2D2D2D;
        line-height: 22px;
      }
    }
  }
}
</style>
