<template>
  <div class="w">
    <div class="crumbs">
      <router-link to="/">首页</router-link>
      <span>/</span>
      {{ route.query.titleOne }}
      <span>/</span>
      <span style="color: #07C160">
      {{ route.query.titleTwo }}
      </span>
    </div>
    <div class="types" v-if="route.query.category ==3">
      <div :class="{active: !newsCategory}" @click="changeCategory(0)">全部</div>
      <div :class="{active: newsCategory === 1}" @click="changeCategory(1)">监测规范</div>
      <div :class="{active: newsCategory === 2}" @click="changeCategory(2)">监测标准</div>
      <div :class="{active: newsCategory === 3}" @click="changeCategory(3)">监测方法</div>
      <div :class="{active: newsCategory === 4}" @click="changeCategory(4)">监测站点</div>
      <div :class="{active: newsCategory === 5}" @click="changeCategory(5)">趋势动态</div>
    </div>
    <div class="types" v-if="route.query.category==4">
      <div :class="{active: !newsCategory }" @click="changeCategory(0)">全部</div>
      <div :class="{active: newsCategory === 1}" @click="changeCategory(1)">防治动态</div>
      <div :class="{active: newsCategory === 2}" @click="changeCategory(2)">防治技术</div>
      <div :class="{active: newsCategory === 3}" @click="changeCategory(3)">防治标准</div>
      <div :class="{active: newsCategory === 4}" @click="changeCategory(4)">防治方法</div>
    </div>
    <div class="types" v-if="route.query.category==5">
      <div :class="{active: !newsCategory }" @click="changeCategory(0)">全部</div>
      <div :class="{active: newsCategory === 1}" @click="changeCategory(1)">检疫动态</div>
      <div :class="{active: newsCategory === 2}" @click="changeCategory(2)">检疫技术</div>
      <div :class="{active: newsCategory === 3}" @click="changeCategory(3)">检疫性有害生物</div>
      <div :class="{active: newsCategory === 4}" @click="changeCategory(4)">检疫申报指南</div>
    </div>
    <div class="types" v-if="route.query.category==6">
      <div :class="{active: !newsCategory}" @click="changeCategory(0)">全部</div>
      <div :class="{active: newsCategory === 1}" @click="changeCategory(1)">法规动态</div>
      <div :class="{active: newsCategory === 2}" @click="changeCategory(2)">技术广角</div>
      <div :class="{active: newsCategory === 3}" @click="changeCategory(3)">基础库</div>
    </div>
    <ul class="list">
      <li v-for="item of list" :key="item.id">
        <router-link :to="{path: 'grassDetial', query: {id: item.id, type: route.query.titleTwo}}">
          <span><i></i>{{ item.subject }}</span>
          <div>
            {{ formartTime(item.createTime) }}
          </div>
        </router-link>
      </li>
    </ul>
    <div class="pagebox">
      <el-pagination
        layout="prev, pager, next"
        :page-size="12"
        :current-page="pageNo"
        @current-change="pageChange"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from 'vue'
import {ElPagination} from 'element3'
import {getTextDetails} from '../api'
import {useRoute, useRouter} from 'vue-router'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'List',
  components: {
    ElPagination
  },
  watch: {
    // this.$route.path
    '$route.query': function (val) {
      this.newsCategory = Number(val.type) || 0
      this.pageNo = 1
      this.getListData()
    }
  },
  setup() {
    const list = ref([])
    const pageNo = ref(1)
    const total = ref(0)
    const newsCategory = ref('')

    const route = useRoute()
    const router = useRouter()

    const getListData = () => {
      getTextDetails(route.query.url, {
        id: route.query.id,
        pid: route.query.pid,
        category: route.query.category,
        subtype: newsCategory.value === 0 ? '' : newsCategory.value,
        pageNo: pageNo.value,
        pageSize: 12
      }).then(res => {
        total.value = res.result.total
        list.value = res.result.records
      })
    }
    onMounted(() => {
      console.log(router.query)
      getListData()
    })

    const pageChange = (page) => {
      pageNo.value = page
      getListData()
    }

    const changeCategory = (type) => {
      newsCategory.value = type
      pageNo.value = 1
      getListData()
    }

    const formartTime = (val) => {
      return dayjs(val).format('YYYY/MM/DD')
    }

    return {list, pageNo, route, total, pageChange, changeCategory, newsCategory, getListData, formartTime}
  }
})
</script>

<style>
.el-pager li {
  min-width: 28px;
}

.crumbs {

em {
  font-style: normal;
  color: #07C160;
}

}

.el-pager li.active {
  background: #07C160;
  border-radius: 50%;
  color: #ffffff;
  padding: 0;
}
</style>
<style lang="scss" scoped>
.types {
  border-bottom: 1px solid #dddddd;
  margin-top: 30px;
  display: flex;
  margin-bottom: 30px;

  & > div {
    flex: 1;
    text-align: center;
    line-height: 50px;
    font-size: 22px;
    cursor: pointer;

    &.active {
      color: #07C160;
      border-bottom: 1px solid #07C160;
    }
  }
}

.list {
  margin-bottom: 50px;
  border-bottom: 1px solid #DDDDDD;

  li {
    width: 1152px;
    margin: 0 auto;
    border-bottom: 1px dotted #DDDDDD;
    line-height: 55px;

    &:last-child {
      border-bottom: 0;
    }
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;

    &:hover > div, &:hover > span {
      color: #07C160;
    }
  }

  span {
    display: block;
    align-items: center;
    width: 900px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }

  i {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
    background: #333333;
  }
}
</style>
