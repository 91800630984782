<template>
  <div style="background: #fff;">
    <div class="crumbs" style="background: #FFFFFF">
      <router-link to="/">首页</router-link>
      <span>/</span>
      <span>林草保护</span>
      <span>/</span>
      <span style="color: #07C160">林草分布</span>
    </div>
    <div class="protection w">
      <div class="protection-left">
        <div class="titleAndMore">
          <div class="leftTitle"></div>
        </div>
        <div class="protection-left-map">
          <div style="width: 600px;height: 490px" id="amap"/>
        </div>
      </div>
      <div class="protection-right">
        <div style="font-size: 14px">{{ city === '云南' ? '全省' : city }}草原面积:</div>
        <div style="font-size: 26px">{{ areaSum }}hm²</div>
        <div style="margin-top: 10px;font-size: 14px">{{ city === '云南' ? '全省' : city }}草地类：</div>
        <span style="margin-right: 10px;font-size: 14px" v-for="item in grasslandCategories">{{ item }}</span>
        <div style="width: 600px;margin-top: 10px">
          <el-table :data="tableData" height="450" style="width: 100%" border>
            <el-table-column prop="area" label="区域"/>
            <el-table-column prop="prairieArea" label="草原面积"/>
            <el-table-column prop="categories" label="草地类"/>
            <el-table-column v-if="level===3" label="操作" width="100">
              <template #default="scope">
                <a type="text" style="color: #1890ff;cursor: pointer" @click="lookGrass(scope.row)">查看草班</a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="back" v-if="level===3" @click="back">返回</div>
    </div>
  </div>
  <grass-map v-if="alertShow" :visible="alertShow" :city='level2' :county="level3"></grass-map>
</template>

<script>
import {defineComponent, onMounted, ref, provide} from 'vue'
import {getAction} from '../api/index'
import {useRoute, useRouter} from 'vue-router'
import echarts from 'echarts'
import {ElMessage} from 'element-plus'
import pinyin from "../../node_modules/js-pinyin/index";
import grassMap from "../components/grassMap";

require('echarts/lib/chart/map')
require('echarts/map/js/china')

export default defineComponent({
  name: 'Industry',
  components: {grassMap},
  setup() {
    const alertShow = ref(false)
    const tableData = ref(null)
    const route = useRoute()
    const mapChart = ref(null)
    const city = ref(null)
    const cityCodeList = ref(null)
    const areaSum = ref(null)
    const grasslandCategories = ref(null)
    const level = ref(null)
    const level2 = ref(null)// 市
    const level3 = ref(null)// 县
    const mapData = ref([])

    const initMap = (name) => {
      if (!name) {
        city.value = '云南'
        require('echarts/map/js/province/' + pinyin.getFullChars(city.value).toLowerCase() + '.js')
      } else {
        city.value = name
      }
      mapChart.value = echarts.init(document.getElementById('amap'))
      mapChart.value.setOption({
        visualMap: {
          left: 'left',
          min: 0,
          max: 5,
          pieces: [
            {min: 3000, label: '≥3000h㎡'}, // 不指定 max，表示 max 为无限大（Infinity）。
            {min: 2000, max: 2999, label: '2000~2999h㎡'},
            {min: 1000, max: 1999, label: '1000~1999h㎡'},
            {min: 100, max: 999, label: '100~999h㎡'},
            {min: 1, max: 99, label: '1~99h㎡'}, // 不指定 min，表示 min 为无限大（-Infinity）。
            {max: 0, label: '0h㎡'} // 不指定 min，表示
          ],
          inRange: {
            color: ['#F1FBFF', '#3AA1FF']
          },
          type: 'piecewise',
          calculable: true
        },
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: function (params) {
            if (isNaN(params.value)) {
              params.value = 0
            }
            return params.name + ': ' + params.value + 'hm²'
          }
        },
        series: [
          {
            type: 'map',
            map: city.value,

            itemStyle: {
              // 地图背景色
              normal: {
                areaColor: '#F1FBFF',
                borderColor: '#0B6ACC'
              }
            },
            data: mapData._rawValue
          }]
      }, true)
      mapChart.value.resize()
      mapChart.value.on('click', function (params) {
        level2.value = params.name
        cityCodeList.value.forEach(item => {
          if (item.name == params.name.replace('自治州', '').replace('市', '')) {
            getTableMap(params.name, item.dcode)
          }
        })
      })// 点
    }
    const getCityMapJson = (name, code) => {
      code = code + '_full'
      getAction('/yncy/sys/common/transitRESTful', {url: 'https://geo.datav.aliyun.com/areas_v3/bound/' + code + '.json'}).then(res => {
          echarts.registerMap(name, res.result)
        initMap(name)
        }
      )
    }
    const getTableMap = (cityName, code) => {
      getAction('/yncy/greedLandArea/grassDistribution', {
        province: '云南省', city: cityName ? cityName : null
      }).then(res => {
        tableData.value = res.result.distribution
        areaSum.value = res.result.areaSum
        grasslandCategories.value = res.result.grasslandCategories
        level.value = res.result.level
        res.result.distribution.forEach(item => {
          mapData.value.push({name: item.area, value: item.prairieArea});
        })
        if (cityName) {
          getCityMapJson(cityName, code)
        } else {
          initMap()
        }
      })
    }
    const getCityCode = (value) => {
      getAction('/yncy/district/sysDistrict/queryByName', {name: value}).then((res, e) => {
        cityCodeList.value = res.result
      }).catch(err => {
        console.log(err)
      })
    }
    // 查看草班
    const lookGrass = (row) => {
      if (window.localStorage.getItem('yncy_token')) {
        level3.value = row.area
        alertShow.value = true
      } else {
        ElMessage({
          message: '请登录！',
          type: 'warning'
        })
      }
    }
    const back = () => {
      initMap('云南')
      getTableMap(null)
    }
    const closeMap = () => {
      alertShow.value = false
    }
    provide('closeMap', closeMap)
    onMounted(() => {
        getCityCode('云南')
        // initMap()
        getTableMap()
      }
    )
    return {tableData, lookGrass, alertShow, areaSum, grasslandCategories, level, level2, level3, city, back, closeMap,getTableMap}
  }
})
</script>
<style lang="scss" scoped>
.crumbs {
  padding-top: 20px;
  width: 1200px;
  margin: 0 auto;
}

.protection {
  position: relative;
  margin-top: 20px;
  height: 600px;
  height: 600Px;
  background: #FFFFFF;
  cursor: pointer;
  display: flex;

  .back {
    position: absolute;
    left: 0;
    top: 5px;
  }

  .protection-left {
    width: 550px;
    height: 565px;
  }

  .protection-right {
    width: 650px;
    height: 565px;

    .protection-right-content {
      height: 490px;

      .protection-right-content-item {
        height: 224px;


        .protection-right-content-item-content {
          margin-top: 16px;
          height: 165px;
          display: flex;

          .protection-right-content-item-content-img {
            width: 220px;
            height: 165px;
          }

          .protection-right-content-item-content-text {
            width: 515px;
            height: 156px;
            margin: 0 15px 0px 24px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 26px;
          }
        }
      }

    }

    .protection-list {
      .protection-list-item {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover > div {
          color: #07C160;
        }

        .protection-list-item-title {
          margin-left: 20px;
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }

        .protection-list-item-time {
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 22px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
