<template>
  <div class="w">
    <div class="crumbs" style="position: relative">
      <router-link to="/">首页</router-link>
      <span>/</span>
      便民服务
      <span>/</span>
      <span style="color: #07C160">办证指南</span>
      <span style="display:inline-block;position: absolute;right: 0;top: -9px">
        <el-button size="mini" type="success">检疫报检申请表下载</el-button>
      </span>
    </div>
    <div class="guideContent">
      <div class="guideContent_img">
<!--        <div class="guideContent_img_text">-->
<!--          <div>澄江县受理单位： 澄江县林业有害生物防治检疫站(简称林检站)</div>-->
<!--          <div>地址： 澄江县凤麓街道办环城北路45号(老林业局3楼)</div>-->
<!--          <div>电话： 0877-6910326</div>-->
<!--        </div>-->
      </div>
      <div class="guideContent_text">
        <hr/>
        <div class="title">中华人民共和国植物检疫条例</div>
        <div class="guideContent_text_content">
          <span style="font-weight: 600;">第七条调运植物和植物产品，属于下列情况的，必须经过检疫:</span><br/>
          (一) 列入应施检疫的植物、植物产品名单的，运出发生疫情的县级行政区域之前，必须经过检疫;<br/>
          (二) 凡种子、苗木和其他繁殖材料，不论是否列入应施检疫的植物、植物产品名单和运往何地，在调运之前，都必须经过检疫。
        </div>
        <div class="title">植物检疫条例实施细则(林业部分)</div>
        <div class="guideContent_text_content">
          <span style="font-weight: 600;">第六条应施检疫的森林植物及其产品包括:</span><br/>
          (一)林木种子、苗木和其他繁殖材料;<br/>
          (二)乔木、灌木、竹类、花卉和其他森林植物;<br/>
          (三)木材、竹材、药材、果品、盆景和其他林产品。
        </div>
        <div class="guideContent_text_content">
          <span style="font-weight: 600;">第十四条应施检疫的森林植物及其产品运出发生疫情的县级行政区域之前以及调运林木种子、苗木和其他繁殖材料必须经过检疫，取得《植物检疫证书》。</span><br/>
          《植物检疫证书》由省、自治区、直辖市森检机构按规定格式统- - 印制。<br/>
          《植物检疫证书》按- -车(即同- -运输工具)一证核发。
        </div>
        <div class="guideContent_text_content">
          <span style="font-weight: 600;">第三十条有下列行为之一的，森检机构应当责令纠正，可以处以50元至2000元罚款;造成损失的，应当责令赔偿;构成犯罪的，由司法机关依法追究刑事责任。</span><br/>
          (一)未依照规定办理《植物检疫证书》或者在报检过程中弄虛作假的;<br/>
          (二)伪造、涂改、买卖、转让植物检疫单证、印章、标志、封识的;<br/>
          (三)未依照规定调运、隔离试种或者生产应施检疫的森林植物及其产品的;<br/>
          (四)违反规定，擅自开拆森林植物及其产品的包装，调换森林植物及其产品，或者擅自改变森林植物及其产品的规定用途的;<br/>
          (五)违反规定，引起疫情扩散的。
        </div>
        <div class="textSSS">
          有前款第(一)、(二)、(三)、(四)项所列情形之一一，尚不构成犯罪的，森检机构可以没收非法所得。对违反规定调运的森林植物及其产品，森检机构有权予以封存、没收、销毁或者责令改变用途。销毁所需费用由责任人承担。
        </div>
        <div class="title">中华人民共和国刑法修正案</div>
        <div class="guideContent_text_content">
          第三百三十七条违反有关动植物防疫、 检疫的国家规定，引起重大动植物疫情的，或者有引起重大动植物疫情危险，情节严重的，处三年以下有期徒刑或者拘役，并处或者单处罚金。单位犯前款罪的，对单位判处罚金，并对其直接负责的主管人员和其他直接责任人员，依照前款的规定处罚。
        </div>
      </div>
    </div>
   </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { ElPagination } from 'element3'
import { yncySystem } from '../api'

export default defineComponent({
  name: 'PeriodicalList',
  components: {
    ElPagination
  },
  setup () {
    const list = ref([])
    const pageNo = ref(1)
    const total = ref(0)

    const getListData = () => {
      yncySystem({ pageNo: pageNo.value, pageSize: 12 }).then(res => {
        total.value = res.result.total
        list.value = res.result.records
      })
    }
    onMounted(() => {
      getListData()
    })

    const pageChange = (page) => {
      pageNo.value = page
      getListData()
    }

    return { list, pageNo, total, pageChange, getListData }
  }
})
</script>

<style lang="scss" scoped>
  .guideContent{
    margin: 48px 33px 0px 33px;
    width: 1134px;
    .guideContent_img{
      margin-bottom: 40px;
      width: 100%;
      height: 1168px;
      background: url("../assets/guideBackground.png") no-repeat;
      position: relative;
      .guideContent_img_text{
        width: 500px;
        height: 102px;
        position: absolute;
        left: 0;
        bottom: 0;
        div{
          margin-top: 8px;
          height: 26px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2D2D2D;
          line-height: 26px;
        }
      }
    }
    .guideContent_text{
      .title{
        text-align: center;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
      }
      .guideContent_text_content{
        margin-top: 24px;
        margin-bottom: 32px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #2D2D2D;
        line-height: 22px;
      }
      .textSSS{
        margin-top: -20px;
        margin-bottom: 20px;
        width: 1111px;
        height: 56px;
        background: #F2F2F2;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2D2D2D;
        line-height: 20px;
      }
    }
  }
</style>
