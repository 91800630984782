<template>
  <div class="w">
    <div class="crumbs">
      <router-link to="/">首页</router-link>
      <span>/</span>
      专题专栏
      <span>/</span>
      <span style="color: #07C160">专题列表</span>
    </div>
    <section class="container">
      <div class="content">
        <div class="content-item" v-for="item in list" @click="specialDetail(item.id)" @mouseover="mouseOver($event)"
             @mouseleave="mouseLeave($event)">
          <img :src="item.cover"/>
          <div class="item-info">
            <div class="item-info-title">{{ item.title }}</div>
            <div class="item-info-time">{{ formartTime(item.createTime) }}</div>
          </div>
        </div>
      </div>
      <div class="pagebox">
        <el-pagination
          layout="prev, pager, next"
          :page-size="12"
          :current-page="pageNo"
          @current-change="pageChange"
          :total="total">
        </el-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted} from 'vue'
import {getAction} from '../api'
import {useRoute, useRouter} from 'vue-router'
import dayjs from 'dayjs'
import {ElPagination} from 'element3'

export default defineComponent({
  components: {ElPagination},
  name: 'Industry',
  setup() {
    const list = ref([])
    const activeID = ref(0)
    const userInfo = ref({})
    const pageNo = ref(1)
    const total = ref(0)
    const router = useRouter()

    const getColumnList = () => {
      getAction('/yncy/column/list', {pageSize: 9, pageNo: pageNo.value}).then(res => {
        if (res.success) {
          list.value = res.result.records
          total.value = res.result.total

        }
      })
    }
    const checkUser = (row) => {
      activeID.value = row.id
      userInfo.value = row
    }

    const info = ref({})
    const show = ref({
      one: true,
      two: false,
      three: false
    })

    const changeShow = (num) => {
      show.value[num] = !show.value[num]
    }

    const route = useRoute()

    const formartTime = (val) => {
      return dayjs(val).format('YYYY-MM-DD')
    }

    const pageChange = (page) => {
      pageNo.value = page
      getColumnList()
    }
    onMounted(() => {
      getColumnList()
      for (const key in show.value) {
        show.value[key] = false
      }
      show.value[route.query.type || 'one'] = true
    })
    const mouseOver = (e) => {
      e.target.parentNode.children[1].style.backgroundColor = '#00BB3D'
      e.target.parentElement.children[1].children[0].style.color = '#fff'
      e.target.parentElement.children[1].children[1].style.color = '#fff'
    }
    const mouseLeave = (e) => {
      e.target.children[1].style.backgroundColor = '#fff'
      e.target.children[1].children[0].style.color = '#000'
      e.target.children[1].children[1].style.color = 'rgba(0, 0, 0, 0.45)'
    }

    const specialDetail = (id) => {
      console.log(id)
      router.push({
        name: 'NewsLists',
        query: {
          url: '/yncy/columnList/list',
          pid: id,
          titleOne: '专题专栏',
          titleTwo: '专题列表'
        }
      })
      // {path:'/NewsLists', query:{url:'/yncy/grassProtect/list',category:4}}
    }

    return {
      total,
      pageNo,
      list,
      info,
      show,
      changeShow,
      activeID,
      userInfo,
      checkUser,
      formartTime,
      pageChange,
      mouseOver,
      mouseLeave,
      specialDetail
    }
  }
})
</script>

<style lang="scss" scoped>
/deep/ .el-pager li.active {
  background: #07C160;
  border-radius: 50%;
  color: #ffffff;
  padding: 0;
}

.active {
  div {
    color: #07C160 !important;
  }
}

.content {
  padding: 20px 25px 0px 25px;
  display: flex;
  flex-wrap: wrap;

  .content-item {
    position: relative;
    margin-left: 12px;
    margin-top: 12px;
    width: 275px;
    height: 210px;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }

    .item-info {
      position: absolute;
      left: 10px;
      bottom: 12px;
      width: 256px;
      height: 66px;
      background: #FFFFFF;
      opacity: 0.85;
      pointer-events: none;

      .item-info-title {
        text-align: center;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        margin-top: 10px;
      }

      .item-info-time {
        text-align: center;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 20px;
      }
    }
  }

}

.detail-body {
  font-size: 16px;
  line-height: 20px;
}

.crumbs {
  em {
    font-style: normal;
    color: #07C160;
  }
}

.title {
  text-align: center;
  font-size: 22px;
}

.info {
  line-height: 38px;
  text-align: center;
  color: #999999;
  border-bottom: 1px dotted #999999;
  margin-bottom: 60px;

  &-source {
    margin-right: 20px;
  }
}

.container {
  margin-top: 20px;
  margin-bottom: 90px;
}

.content {
  flex: 1;
}

.list-box .item {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #00BB3D;
  }

  &:hover .item-left {
    color: #FFFFFF;
  }

  .item-left {
    margin-left: 30px;
  }

  .item-right {
    margin-right: 30px;
  }

  .item-right:after {
    content: "";
    width: 10px;
    height: 10px;
    display: inline-block;
    border-top: 2px solid darkgray;
    border-right: 2px solid darkgray;
    transform: rotate(45deg);
  }

  &:hover .item-right:after {
    border-color: #FFFFFF;
  }
}

.list {
  width: 246px;
  border: 1px solid #DDDDDD;
  margin-right: 18px;
  height: 100%;

  &-box {
    height: 400px;
  }

  ul {
    margin-bottom: 20px;
  }

  li {
    cursor: pointer;
    height: 47px;
    line-height: 47px;
    border-bottom: 1px dotted #DDDDDD;
    width: 210px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;

    &.active {
      color: #07C160;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &-title {
    border-left: 5px solid #07C160;
    font-size: 18px;
    padding-left: 6px;
    color: #333333;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;

    i {
      display: block;
      width: 13px;
      height: 8px;
      background: url(../assets/arrow.png) no-repeat;
      background-size: 13px 8px;

      &.show {
        transform: rotate(180deg);
      }
    }
  }

  &-name {
    height: 64px;
    line-height: 64px;
    text-align: center;
    color: #ffffff;
    background: #07C160;
    font-size: 22px;
    font-weight: 700;
  }
}

.pagebox {
  margin: 25px auto 0px auto;
}
</style>
