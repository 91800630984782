<template>
  <div class="videoModel">
    <el-dialog
      v-model="mapVisible"
      title="查看草班"
      width="30%"
      @close="handleClose"
    >
      <div id="grassMap"></div>
    </el-dialog>
  </div>
</template>

<script>
import {getCurrentInstance, defineComponent, onMounted, ref, inject, nextTick} from 'vue'
import {getAction, postAction} from '../api/index'
import axios from "axios";

export default defineComponent({
  name: 'List',
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    city: {
      type: String,
      required: true
    },
    county: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const mapVisible = ref(false)
    const map = ref(null)
    const districtSearch = ref(null)
    const polygonTool = ref(null)
    const polygonArr =ref([])
    const initMap = () => {
      let div = document.getElementById('grassMap')
      map.value = new AMap.Map('grassMap', {
        layers: [ new AMap.TileLayer.Satellite()],
        resizeEnable: true,
        zoom: 11, //初始化地图层级
        center: [116.397428, 39.90923] //初始化地图中心点
      })
      map.value.plugin(['AMap.DistrictSearch'], () => {
        districtSearch.value = new AMap.DistrictSearch({
          // 关键字对应的行政区级别，country表示国家
          extensions: 'all',
          level: 'district'
        })
        districtSearch.value.search(props.county,function (status, result) {
          console.log(status,result)
          if (status == 'complete') {
            result.districtList[0].boundaries.forEach((item, index) => {
              polygonTool.value = new AMap.Polygon({
                map: map.value,
                strokeColor: '#1890ff',
                strokeWeight: 3,
                fillColor: '#1890ff',
                fillOpacity: 0,
                path: result.districtList[0].boundaries[index]
              })
              polygonArr.value.push(polygonTool.value)
            })
            map.value.setCenter(result.districtList[0].center)
          }
        })
      })
    }
    const handleClose = inject('closeMap')
    const wmsLayer = ref(null)
    const adddWsmLayer = (url, ereaCode) =>{
      const config = {
        // url:this.WmsParams.baseUrl,
        // this.WmsParams.areaName
        url: url,
        blend: false,
        tileSize: 512,
        params: { LAYERS: ereaCode, VERSION: '1.0.0' }
      }
      console.log(config)
      if (wmsLayer.value) {
        map.value.removeLayer(wmsLayer.value)
      }
      wmsLayer.value = new AMap.TileLayer.WMS(config)
      wmsLayer.value.setMap(map.value)
    }
    const getWmsData = (val) => {
      getAction('/yncy/sysWmsData/getWmsData', {
        province: '云南省',
        city: props.city,
        county: props.county
      }).then(res => {
        adddWsmLayer(res.result.baseUrl, res.result.areaName)
      }).catch(e=>{
        console.log('111',e)
      })
    }
    onMounted(() => {
      getWmsData()
      mapVisible.value = props.visible
      nextTick(() => {
        initMap()
      })
    })

    return {
      mapVisible,
      handleClose
    }
  }
})
</script>
<style scoped lang="scss">
/deep/ .el-dialog {
  width: 850px !important;
  margin-top: 100px !important;
}

#grassMap {
  width: 100%;
  height: 500px;
}
</style>
